import axios from 'axios'
import Cookies from 'universal-cookie'

export const ApiUrlBase = process.env.REACT_APP_API_URL
// export const ApiUrlBase = 'http://ns-dev.ctaspro.com:8080'

const cookies = new Cookies()

export const axiosCtasBE = axios.create({
    baseURL: ApiUrlBase,
    timeout: 20000,
    withCredentials: true,
})

const delay = async (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve('Done'), ms)
    })
}

const redirectToLogin = () => {
    // TECH DEBT: Figure out if we can get appId (CTAS) from the store here or should we send it by parameters or set another file... for now we just hardcode it
    window.location.href = `${process.env.REACT_APP_COGNITO_LOGIN}%2FCTAS`
}

export const getValidToken = () => {
    const authToken = cookies.get('lock')
    if (!authToken) {
        redirectToLogin();
        return ''
    }

    // I can open the token, check expire and refresh it beforehand
    return authToken
}

export const apiGetCall = async (url: string) => {
    const validToken = getValidToken();
    if (validToken) {
            try {
                console.log("### API ", `${ApiUrlBase}/${url}`)
                const response = await axios.get(`${ApiUrlBase}/${url}`,{
                    headers: {
                        Authorization: validToken,
                        "http-equiv": "Content-Security-Policy",
                        "content": "upgrade-insecure-requests"
                    }
                })
                return response
            } catch (err: any) {
                if (err.response?.status === 401) {
                    console.log("401 => Redirecting to Login")
                    cookies.remove('lock')
                    redirectToLogin()
                } else {
                    throw new Error(`Error en los Datos - ${url}`);
                }
            }
    }
}

export const apiPostCall = async (url: string, params: any) => {
    const validToken = getValidToken();
    if (validToken) {
        try {
            const response = await axios.post(`${ApiUrlBase}/${url}`, params,{
                headers: {
                    Authorization: validToken
                }
            })
            return response
        } catch (error: any) {
            if (error.response?.status === 401) {
                console.log("401 => Redirecting to Login")
                cookies.remove('lock')
                redirectToLogin()
            } else {
                console.error(error)
                throw new Error(error)
            }
        }
    }
}

export const apiDelCall = async (url: string) => {
    const validToken = getValidToken();
    if (validToken) {
        try {
            const response = await axios.delete(`${ApiUrlBase}/${url}`,{
                headers: {
                    Authorization: validToken
                }
            })
            return response
        } catch (error: any) {
            if (error.response?.status === 401) {
                console.log("401 => Redirecting to Login")
                cookies.remove('lock')
                redirectToLogin()
            }
        }
    }
}
