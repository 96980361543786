import { useState, CSSProperties, useEffect } from 'react'
import { TabParams, Title } from '../../models/tabs.model'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../store/slices/data.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { styleComponentContainer, styledSectionBox } from '../../styles/shared-components.style';
import { updatePaginationValues } from '../../../store/slices/tabs.slice';
import { Filters } from '../Filter/Filter.component';
import filter from '../Filter/filter.helper';
import { Paginator } from '../Paginator/Paginator.componet';
import { BrowseRow, BrowseRowBlank, BrowseTitle } from './Rows.component';

export const Browse = ({browseParams}:
    {
        browseParams: TabParams,
    }
) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const { fullList } = useSelector((state: any) => state.data.data[browseParams.dataId.toLowerCase()])

    useEffect(() => {
        dispatch(fetchData({dataType: browseParams.dataId}))
    }, [])

    return (
        <div style={styleComponentContainer}>
            {browseParams.filters?.length && <Filters tab={browseParams} />}
            <div style={styleDataContainer}>
                <table style={{width: '100%'}}>
                    <BrowseTitle 
                        titles={browseParams.browseTitles || []}
                        showActions={2} // TODO: Calculate this with a function to see how many Actions
                    />
                    <BrowseRows 
                        browseParams={browseParams}
                        rawData={fullList}
                    />
                </table>
                <br />
                {browseParams.pagination &&
                    <Paginator tab={browseParams}/>
                }
            </div>
        </div>
    )
}

const styleDataContainer: CSSProperties = {
    ...styledSectionBox,
    width: '70%',
}

const BrowseRows = ({browseParams, rawData}: {
    browseParams: TabParams, rawData: any[]
}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [rows, setRows] = useState([] as any[])

    useEffect(() => {
        const filteredData = filter(rawData || [], browseParams.filters || [])
        dispatch(updatePaginationValues({tabId: browseParams. id, values: {...browseParams.pagination!, totalRows: filteredData.length} }))
        
        const paginatedData = (browseParams.pagination?.currentPage) ?
        filteredData.slice((browseParams.pagination?.currentPage - 1) * browseParams.pagination.rowsPerPage, (browseParams.pagination?.currentPage) * browseParams.pagination?.rowsPerPage)
        .map(fd => ({...fd, rowType: 'DATA'}))
        : []
        
        if (paginatedData.length !== browseParams.pagination!.rowsPerPage!) {
            let extraRows = (browseParams.pagination?.rowsPerPage! - paginatedData.length)
            do {
                paginatedData.push({rowType: 'BLANK'})
                extraRows = extraRows -1
            } while (extraRows > 0)
        }
        setRows(paginatedData)
    }, [rawData, browseParams.pagination?.forceUpdate, browseParams.pagination?.currentPage])

    return (
        <tbody>
            {rows.map((v, index) => 
                {
                    if (v.rowType === 'DATA' ) {
                        return <BrowseRow 
                            key={index}
                            browseParams = {browseParams}
                            values = {v}
                        />
                    } else if (v.rowType === 'BLANK') {
                        return <BrowseRowBlank />
                    }
                }
            )}
        </tbody>
    )
}
