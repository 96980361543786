import { useEffect } from 'react';
import { Header } from "./app/Shared/Header/Header.component";
import { TabsComponent } from './app/Shared/Tabs/Tabs.component';
import { Login } from './app/auth/Authorizer';
import { BrowserRouter, Route, Routes,  } from 'react-router-dom';
import { apiGetCall } from './app/data/api.data';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setAppId } from './store/slices/data.slice';


const AccountsApp = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

  useEffect(() => {
    dispatch(setAppId('CTAS'))

    let urlParams = new URLSearchParams(window.location.search);
    
    if (!urlParams.get('code')) {
      apiGetCall('verify')
    }
  }, [])

  return (
    <div className="App">
      <Header />
      <TabsComponent />
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/accounts' element={<AccountsApp />} />
        <Route path='/login/:appId' element={<Login />} />
        <Route path='/' element={`<h1>Landing Page! X1-${process.env.REACT_APP_NAME}</h1>`} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
