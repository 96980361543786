import { createSlice } from '@reduxjs/toolkit';
import { TabParams, EditField, Pagination } from '../../app/models/tabs.model';
import { BrowseFilter } from '../../app/models/tabs.model';

export interface TabSliceState {
    selected: string,
    openTabs: TabParams[]
}

export const tabsSlice = createSlice({
    name: 'tabs',
    initialState: {
        selected: '',
        openTabs: [] as TabParams[]
    },
    reducers: {
        addTab: (state, action: {payload: TabParams}) => {
            if (!state.openTabs.find((ot: TabParams) => ot.id === action.payload.id)) {
                state.openTabs.push(action.payload)
            }
            state.selected = action.payload.id
        },
        removeTab: (state, action: {payload: string}) => {
            const tabToRemoveIndex = state.openTabs.findIndex(t => t.id === action.payload)
            if (tabToRemoveIndex !== undefined) {
                state.openTabs.splice(tabToRemoveIndex,1)
                if (state.openTabs.length > 0) {
                    state.selected = state.openTabs[0].id
                } else {
                    state.selected = ''
                }
            }
        },
        selectTab: (state, action: {payload: string}) => {
            const newSelectedTab = action.payload
            if (newSelectedTab) {
                state.selected = newSelectedTab
            }
        },
        changeTab: (state, action: {payload: TabParams}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.id))
            if (tabToChange) {
                tabToChange = action.payload
            }
        },
        updateEditValues: (state, action: {payload: {tabId: string, values: EditField[]}}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.tabId))
            if (tabToChange) {
                tabToChange.editFields = action.payload.values
            }
        },
        updateFilterValues: (state, action: {payload: {tabId: string, filterId: string, newValue: any}}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.tabId))
            if (tabToChange) {
                tabToChange.filters?.filter(f => 
                    f.filterId === action.payload.filterId
                    ||
                    f.copyValueFrom === action.payload.filterId
                ).forEach(f => {
                    f.value = action.payload.newValue
                })
            }
        },
        applyFilters: (state, action: {payload: {tabId: string}}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.tabId))
            if (tabToChange) {
                tabToChange.filters?.forEach(f => f.applied = f.value)
                if (tabToChange.pagination) {
                    tabToChange.pagination.forceUpdate = tabToChange.pagination.forceUpdate ? tabToChange.pagination.forceUpdate + 1 : 1
                }
            }
        },
        updateParametersValues: (state, action: {payload: {tabId: string, parameterId: string, newValue: any}}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.tabId))
            if (tabToChange) {
                tabToChange.parameters?.filter(p => 
                    p.filterId === action.payload.parameterId
                    ||
                    p.copyValueFrom === action.payload.parameterId
                ).forEach(f => {
                    f.value = action.payload.newValue
                })
            }
        },
        applyParameters: (state, action: {payload: {tabId: string, data: any[]}}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.tabId))
            if (tabToChange) {
                tabToChange.parameters?.forEach(f => f.applied = f.value)
                tabToChange.cacheData = action.payload.data
            }
        },
        updatePaginationValues: (state, action: {payload: {tabId: string, values: Pagination}}) => {
            let tabToChange = (state.openTabs.find((ot: TabParams) => ot.id === action.payload.tabId))
            if (tabToChange) {
                tabToChange.pagination = action.payload.values
            }
        },
    },
})

export const { 
    addTab, 
    selectTab, 
    changeTab, 
    removeTab ,
    updateEditValues,
    updateFilterValues,
    applyFilters,
    updateParametersValues,
    applyParameters,
    updatePaginationValues,
} = tabsSlice.actions

export default tabsSlice.reducer